import create from 'zustand';
import { nanoid } from 'nanoid';

import { INotify, INotifyStore, TNotifyId } from './types';

export const useNotifyStore = create<INotifyStore>((set, get) => {
  return {
    items: [],

    add: (message, options) => {
      const { remove, items } = get();
      if (items.find((item) => item.id === options?.id))
        return options!.id as TNotifyId;

      const id = options?.id || nanoid(8);
      const duration = options?.duration || 0;
      const position = options?.position || 'top';
      const size = options?.size || 'md';

      set(({ items }) => ({
        items: items.concat({
          id,
          type: 'note',
          ...options,
          message,
          position,
          size,
        }),
      }));

      if (duration > 0) {
        setTimeout(() => {
          remove(id);
        }, duration);
      }

      return id;
    },
    remove: (notifyId) => {
      const { items } = get();

      items.forEach(({ id, onClose }) => {
        if (notifyId === id && onClose) {
          onClose();
        }
      });

      set(({ items }) => ({
        items: items.filter(({ id }) => id !== notifyId),
      }));
    },
    removeByGroup: (groupName: string) => {
      set(({ items }) => ({
        items: items.filter(({ group }) => group !== groupName),
      }));
    },
  };
});

export const addNotify = (message: string, options?: Partial<INotify>) => {
  return useNotifyStore.getState().add(message, options);
};

export const removeNotify = (id: TNotifyId) => {
  useNotifyStore.getState().remove(id);
};

export const removeNotifyByGroup = (group: string) => {
  useNotifyStore.getState().removeByGroup(group);
};

const showErrorNotification = (message: string, duration: number = 5) => {
  addNotify(message, {
    type: 'danger',
    duration: duration * 1000,
  });
};

export const select = {
  itemIndex:
    (id: string | number) =>
    ({ items }: INotifyStore): number => {
      const currentItem = items.find((item) => item.id === id);

      const itemsInSamePosition = items.filter(
        ({ position }) => currentItem?.position === position,
      );

      return itemsInSamePosition
        .reverse()
        .findIndex(({ id }) => currentItem?.id === id);
    },
};
