import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';

export const SWrapper = styled.div`
  position: relative;
`;

export const SSelectMenu = styled(motion.div)`
  position: absolute;
  z-index: 10;
  top: 45px;
  left: 50%;
  width: auto;
  //border-radius: 8px;
  font-family: inherit;
  font-size: 18px;
  border: 2px outset #9b9b9b;
  gap: 10px;
  display: flex;
  flex-direction: column;
  background: white;

  -webkit-box-shadow: 2px 2px 0 1px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 2px 2px 0 1px rgba(0, 0, 0, 0.2);
  box-shadow: 2px 2px 0 1px rgba(0, 0, 0, 0.2);

  @media (max-width: 950px) {
    font-size: 12px;
    top: 35px;
  }
`;

const selectedOrHoverMenuItemStyles = css`
  background: #edeaea;
`;

export const SSelectItem = styled.div<{ selected: boolean; disabled: boolean }>`
  white-space: nowrap;
  cursor: pointer;
  padding: 0 10px;
  &:hover {
    ${selectedOrHoverMenuItemStyles}
  }

  ${({ selected }) => selected && selectedOrHoverMenuItemStyles}
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
    `}
`;

export const SSelectedItem = styled(motion.button)`
  white-space: nowrap;
  display: flex;
  align-items: center;
  gap: 8px;
  user-select: none;
  cursor: pointer;

  //border-radius: 8px;
  padding: 5px 20px;
  font-family: inherit;
  font-size: 18px;

  -webkit-box-shadow: 2px 2px 0 1px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 2px 2px 0 1px rgba(0, 0, 0, 0.2);
  box-shadow: 2px 2px 0 1px rgba(0, 0, 0, 0.2);

  @media (max-width: 950px) {
    font-size: 12px;
  }
`;

export const SIconWrapper = styled.div<{ open: boolean }>`
  height: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s;
  ${({ open }) =>
    open &&
    css`
      transform: rotate(180deg);
    `}
`;
