import { AnimatePresence } from 'framer-motion';
import { useSettingsStore } from 'stores/settings/settings';

import {
  SWrapper,
  SModalWrapper,
  STitle,
  SDescription,
  SRules,
  SRulesTitle,
  SRule,
  SButton,
} from './style';

const titleText = 'Welcome to Wordsy.xyz';
export const Welcome = () => {
  const { isWelcomeDone, doneWelcome } = useSettingsStore();
  const onClick = () => {
    doneWelcome();
  };

  return (
    <SWrapper
      layout
      initial={{ opacity: 0, y: 40, z: 500 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 40 }}
      transition={{ duration: 0.3 }}
    >
      <SModalWrapper>
        <STitle>Welcome to Wordsy.xyz</STitle>
        <SDescription>
          "Wordsy" - is a simple GameFi project designed to make you more
          wordsy. <br />
          And if you turn out to be faster and smarter than other players - take
          the rewards. <br />
          Make sure you are read and understand the rules.
        </SDescription>
        <SRules>
          <SRulesTitle>Rules:</SRulesTitle>
          <SRule>1) You need to guess the hidden word.</SRule>
          <SRule>2) You always see the number of letters in a word.</SRule>{' '}
          <SRule>
            3) You can open a one random letter once every minutes by paying one
            USDC.
          </SRule>
          <SRule>
            4) You have two chances to guess the word (all of us make mistakes).
          </SRule>
          <SRule>
            5) If you are the first to give the correct word - you are the
            winner!
          </SRule>
          <SRule>
            6) The winner receives as a reward all the USDC that were spent by
            him and other players to open the letters of the current word
            (except 10% fee).
          </SRule>
          <SRule>
            7) As soon as the word has been guessed, a new one is given.
          </SRule>
          <SRule>
            8) The game consists of more than a thousand words, but words may be
            repeated (play more - win more).
          </SRule>
          <SRule>
            9) The current word, number of open letters, remaining attempts and
            winners list are unique to each network.
          </SRule>
          <SRule>10) You should learn and enjoy the game! Have fun!</SRule>
        </SRules>
        <SButton onClick={onClick}>Alright!</SButton>
      </SModalWrapper>
    </SWrapper>
  );
};
