import React, { FC, useEffect } from 'react';
import { useAnimation, AnimatePresence } from 'framer-motion';
import { useNotifyStore, select } from 'stores/toast';

import { SWrapper, SWrapperInner, SCloseButton } from './style';
import { positionVariants } from './constants';
import { INotifyProps } from './types';

const Notify: FC<INotifyProps> = ({
  id,
  type,
  message,
  duration,
  onClose,
  position,
  size,
}) => {
  const controls = useAnimation();
  const index = useNotifyStore(select.itemIndex(id));

  useEffect(() => {
    if (index === -1) {
      // controls.start(`${position}_exit`);
    } else {
      controls.start(`${position}_animate`);
    }
  }, [index]);

  return (
    <AnimatePresence>
      {index !== -1 && (
        <SWrapper
          custom={index}
          variants={positionVariants}
          initial={`${position}_initial`}
          animate={controls}
          exit={`${position}_exit`}
          data-type={type}
          data-size={size}
        >
          <SWrapperInner>{message}</SWrapperInner>
        </SWrapper>
      )}
    </AnimatePresence>
  );
};

export default Notify;
