import React, { FC } from 'react';
import { useTimer } from 'react-timer-hook';

const Timer: FC<{
  secondsLeft: number;
  onExpire(): void;
}> = ({ secondsLeft, onExpire }) => {
  const { hours, minutes, seconds } = useTimer({
    expiryTimestamp: new Date(Date.now() + (secondsLeft + 10) * 1000),
    onExpire,
  });

  const zeroPad = (num: number, places: number): string =>
    String(num).padStart(places, '0');

  return (
    <React.Fragment>
      <strong style={{ fontWeight: 500 }}>
        {hours ? `${hours}:` : null}
        {minutes}:{seconds < 10 ? zeroPad(seconds, 2) : seconds}
      </strong>
    </React.Fragment>
  );
};

export default Timer;
