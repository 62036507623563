export const positionVariants = {
  top_initial: (index: number) => ({
    top: 0,
    left: '50%',
    y: -100,
    x: '-50%',
    opacity: 0,
    scale: 0.8,
  }),
  top_animate: (index: number) => {
    return {
      opacity: 1,
      left: '50%',
      x: '-50%',
      y: index * 50,
      scale: 1,
    };
  },
  top_exit: (index: number) => ({
    top: 0,
    opacity: 0,
    y: -30,
    scale: 0.8,
    transition: { duration: 0.2 },
  }),
  rightBottom_initial: (index: number) => ({
    right: 0,
    bottom: 0,
    opacity: 0,
    x: 100,
  }),
  rightBottom_animate: (index: number) => ({
    y: -(index * 50),
    opacity: 1,
    x: -20,
  }),
  rightBottom_exit: (index: number) => ({
    opacity: 0,
    x: 100,
  }),
  leftBottom_initial: (index: number) => ({
    left: 0,
    bottom: 0,
    opacity: 0,
    x: -100,
  }),
  leftBottom_animate: (index: number) => ({
    y: -(index * 50),
    opacity: 1,
    x: 20,
  }),
  leftBottom_exit: (index: number) => ({
    opacity: 0,
    x: -100,
  }),
};
