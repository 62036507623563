import { css } from 'styled-components';

import { pickRandomElementFromArray } from './random';

const fonts = [
  'Young Serif',
  'Pixelify Sans',
  'Yuji Hentaigana Akari',
  'Autour One',
  'Anton',
  'Dancing Script',
  'Pacifico',
  'Lobster',
  'Play',
  'Indie Flower',
  'Lilita One',
  'Russo One',
  'Permanent Marker',
  'Black Ops One',
  'Cinzel',
  'Orbitron',
  'Press Start 2P',
  'Creepster',
  'Special Elite',
  'Bungee',
  'VT323',
  'Bangers',
];

export const getRandomFont = () => {
  const fontFamily = pickRandomElementFromArray(fonts);
  return css`
    font-family: ${fontFamily}, sans-serif;
    font-weight: 400;
  `;
};
