import { words } from 'constants/words';

import { FC, useMemo } from 'react';
import { getRandomNumber, pickRandomElementFromArray } from 'utils/random';

import { SWordsWrapper, SWrapper } from './style';
import { IWordLineProps } from './types';
import { Word } from './Word';

export const WordLine: FC<IWordLineProps> = () => {
  const renderWords = useMemo(
    () =>
      Array(40)
        .fill(null)
        .map(() => pickRandomElementFromArray(words)),
    [],
  );

  const direction = getRandomNumber(0, 1);

  const duration = getRandomNumber(100, 180);
  return (
    <SWrapper>
      <SWordsWrapper
        initial={{ x: direction === 0 ? 0 : '100%' }}
        animate={{ x: direction === 0 ? '100%' : 0 }}
        transition={{ repeat: Infinity, ease: 'linear', duration }}
      >
        {renderWords.map((w, i) => (
          <Word key={`words_1_${w}_${i}`} word={w} />
        ))}
      </SWordsWrapper>
      <SWordsWrapper
        initial={{ x: direction === 0 ? '-100%' : 0 }}
        animate={{ x: direction === 0 ? 0 : '-100%' }}
        transition={{ repeat: Infinity, ease: 'linear', duration }}
      >
        {renderWords.map((w, i) => (
          <Word key={`words_1_${w}_${i}`} word={w} />
        ))}
      </SWordsWrapper>
    </SWrapper>
  );
};
