import { SWrapper, SDot } from './style';

export const Loading = () => {
  return (
    <SWrapper>
      <SDot
        animate={{ y: [-5, 0] }}
        transition={{ repeat: Infinity, repeatType: 'reverse', duration: 0.5 }}
      >
        .
      </SDot>
      <SDot
        animate={{ y: [-5, 0] }}
        transition={{
          repeat: Infinity,
          repeatType: 'reverse',
          duration: 0.5,
          delay: 0.1,
        }}
      >
        .
      </SDot>
      <SDot
        animate={{ y: [-5, 0] }}
        transition={{
          repeat: Infinity,
          repeatType: 'reverse',
          duration: 0.5,
          delay: 0.2,
        }}
      >
        .
      </SDot>
    </SWrapper>
  );
};
