import { FC, memo } from 'react';
import { getRandomFont } from 'utils/font';
import { Interpolation } from 'styled-components/dist/types';

import { SWrapper } from './style';
import { IWordProps } from './types';

const fonts: Record<string, Interpolation<any>> = {};
export const Word: FC<IWordProps> = memo(
  ({ word }) => {
    if (!fonts[word]) {
      fonts[word] = getRandomFont();
    }
    const font = fonts[word];

    return <SWrapper font={font}>{word}</SWrapper>;
  },
  () => true,
);
