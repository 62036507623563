import styled from 'styled-components';
import { motion } from 'framer-motion';
import bgUrl from 'assets/newspaper.png';

export const SWrapper = styled(motion.div)`
  position: relative;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const SModalWrapper = styled(motion.div)`
  border: 2px outset #9b9b9b;
  //border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: url('${bgUrl}');
  width: fit-content;
  padding-bottom: 20px;
  font-family: IM Fell English SC, sans-serif;

  -webkit-box-shadow: 5px 5px 0 1px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 5px 5px 0 1px rgba(0, 0, 0, 0.2);
  box-shadow: 5px 5px 0 1px rgba(0, 0, 0, 0.2);

  @media (max-width: 950px) {
    max-width: calc(100vw - 20px);
  }
`;

export const STitle = styled(motion.div)`
  font-size: 22px;
  font-weight: 600;
  border-bottom: 2px outset #9b9b9b;
  width: 100%;
  text-align: center;
  padding: 10px 0 5px;

  @media (max-width: 950px) {
    font-size: 16px;
  }
`;
export const SDescription = styled(motion.div)`
  text-align: center;
  padding: 20px 0 10px;
  font-size: 18px;
  border-bottom: 2px outset #9b9b9b;
  width: 100%;

  @media (max-width: 950px) {
    font-size: 12px;
  }
`;
export const SRules = styled(motion.div)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  max-width: 680px;
  padding: 20px 20px 0;
  font-size: 16px;

  @media (max-width: 950px) {
    width: calc(100vw - 40px);
    font-size: 10px;
  }
`;
export const SRulesTitle = styled(motion.div)`
  width: 100%;
  text-align: center;
  font-size: 18px;

  @media (max-width: 950px) {
    font-size: 12px;
  }
`;
export const SRule = styled(motion.div)``;

export const SButton = styled(motion.button)`
  cursor: pointer;
  //border-radius: 8px;
  padding: 5px 20px;
  font-family: inherit;
  font-size: 18px;
  margin-top: 20px;
  color: #000;

  -webkit-box-shadow: 2px 2px 0 1px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 2px 2px 0 1px rgba(0, 0, 0, 0.2);
  box-shadow: 2px 2px 0 1px rgba(0, 0, 0, 0.2);

  @media (max-width: 950px) {
    font-size: 12px;
  }
`;
