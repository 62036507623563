import styled from 'styled-components';
import { motion } from 'framer-motion';

export const SWrapper = styled(motion.div)`
  padding: 20px 0 20px;
  display: flex;
  gap: 40px;

  @media (max-width: 950px) {
    gap: 5px;
  }
`;

export const SChar = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border: 2px outset #9b9b9b;
  font-size: 48px;
  backface-visibility: hidden;

  @media (max-width: 950px) {
    font-size: 12px;
    width: 20px;
    height: 20px;
  }
`;
