import { FC, useEffect } from 'react';
import { useGameStore } from 'stores/game';
import { useSettingsStore } from 'stores/settings';

import { Loading } from '../Loading/Loading';

import { IWinnersProps } from './types';
import {
  SHeader,
  SModalWrapper,
  SWrapper,
  SBody,
  SWinner,
  SButton,
} from './style';

export const Winners: FC<IWinnersProps> = () => {
  const { setIsWinners } = useSettingsStore();
  const { getWinners, winners, isWinnersLoading } = useGameStore();

  useEffect(() => {
    getWinners();
  }, []);

  return (
    <SWrapper
      layout
      initial={{ opacity: 0, y: -40 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -40 }}
      transition={{ duration: 0.3 }}
    >
      <SModalWrapper>
        <SHeader>Winners</SHeader>
        <SBody>
          {!isWinnersLoading ? (
            winners?.map((winner, idx) => (
              <SWinner key={`${winner}_${idx}`}>{winner}</SWinner>
            ))
          ) : (
            <Loading />
          )}
          <SButton onClick={() => setIsWinners(false)}>Alright!</SButton>
        </SBody>
      </SModalWrapper>
    </SWrapper>
  );
};
