import { Background } from 'components/Background';
import { AnimatePresence, LayoutGroup } from 'framer-motion';
import { WagmiConfig } from 'wagmi';
import { useWallet } from 'stores/wallet';
import { useSettingsStore } from 'stores/settings';
import { Web3Modal } from '@web3modal/react';
import { useEffect } from 'react';

import { Welcome } from './components/Welcome';
import { Playground } from './components/Playground';
import NotifyProvider from './components/NotifyProvider';
import { Winners } from './components/Playground/Winners/Winners';

import { SWrapper } from './style';
import { useWindowSize } from './hooks/useWindowSize';

function App() {
  const { isWelcomeDone, isWinners } = useSettingsStore();
  const { ethereumClient, projectId, wagmiConfig } = useWallet();

  return (
    <>
      <WagmiConfig config={wagmiConfig}>
        <Web3Modal ethereumClient={ethereumClient} projectId={projectId} />
        <SWrapper>
          <LayoutGroup>
            <AnimatePresence>
              {isWelcomeDone && !isWinners && <Playground />}
            </AnimatePresence>
            <AnimatePresence>
              {!isWelcomeDone && !isWinners && <Welcome />}
            </AnimatePresence>
            <AnimatePresence>
              {isWelcomeDone && isWinners && <Winners />}
            </AnimatePresence>
          </LayoutGroup>
          <NotifyProvider />
          <Background />
        </SWrapper>
      </WagmiConfig>
    </>
  );
}

export default App;
