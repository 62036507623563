import { FC } from 'react';
import { LayoutGroup } from 'framer-motion';

import { IWordProps } from './types';
import { SWrapper, SChar } from './style';

export const Word: FC<IWordProps> = ({ word }) => {
  const variants = {
    close: {
      rotateY: 180,
    },
    open: {
      rotateY: 0,
    },
  };
  return (
    <SWrapper>
      <LayoutGroup>
        {!word &&
          Array(5)
            .fill('')
            .map((_, i) => (
              <SChar
                key={`${i}`}
                layout
                animate={{ rotateY: 360 }}
                transition={{ repeat: Infinity, duration: 1 }}
              />
            ))}
        {word &&
          word.map((c, i) =>
            c ? (
              <SChar
                layout
                initial={{ rotateY: 180 }}
                animate={{ rotateY: 0 }}
                transition={{ duration: 0.5 }}
                key={`${c}_${i}`}
              >
                {c}
              </SChar>
            ) : (
              <SChar key={`${i}`} />
            ),
          )}
      </LayoutGroup>
    </SWrapper>
  );
};
