import styled from 'styled-components';
import { motion } from 'framer-motion';
import bgUrl from 'assets/newspaper.png';

export const SWrapper = styled(motion.div)`
  position: relative;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const SModalWrapper = styled(motion.div)`
  background: #fff;
  border: 2px outset #9b9b9b;
  //border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: url('${bgUrl}');

  padding: 20px;
  gap: 20px;
  font-family: IM Fell English SC, sans-serif;

  -webkit-box-shadow: 5px 5px 0 1px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 5px 5px 0 1px rgba(0, 0, 0, 0.2);
  box-shadow: 5px 5px 0 1px rgba(0, 0, 0, 0.2);

  @media (max-width: 950px) {
    max-width: calc(100vw - 20px);
    padding: 20px 5px;
  }
`;

export const SHeader = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const SHeaderBlock = styled(motion.div)`
  display: flex;
  align-items: center;
  gap: 25px;
  font-size: 18px;

  @media (max-width: 950px) {
    gap: 10px;
    font-size: 12px;
  }
`;

export const SBody = styled(motion.div)`
  padding: 0 0 40px;
  gap: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const SBodyActions = styled(motion.div)`
  display: flex;
  gap: 20px;
`;

export const SUnsupported = styled(motion.div)`
  font-size: 18px;
`;
export const SFooter = styled(motion.div)`
  display: flex;
  gap: 40px;
`;

export const SAddress = styled(motion.button)`
  cursor: pointer;
  font-family: inherit;
  font-size: 18px;
  border: none;

  @media (max-width: 950px) {
    font-size: 12px;
  }
`;

export const SButton = styled(motion.button)`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  //border-radius: 8px;
  padding: 5px 20px;
  font-family: inherit;
  font-size: 18px;
  color: #000;

  -webkit-box-shadow: 2px 2px 0 1px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 2px 2px 0 1px rgba(0, 0, 0, 0.2);
  box-shadow: 2px 2px 0 1px rgba(0, 0, 0, 0.2);

  min-width: 140px;
  @media (max-width: 950px) {
    font-size: 12px;
  }
`;

export const SInput = styled(motion.input)`
  text-align: center;
  font-family: inherit;
  font-size: 22px;
  background: inherit;
  border: none;
  border-bottom: 2px outset #9b9b9b;
  outline: none;

  @media (max-width: 950px) {
    font-size: 16px;
  }
`;
