import { memo } from 'react';

import { SWrapper } from './style';
import { WordLine } from './WordLine';

export const Background = memo(() => {
  return (
    <SWrapper>
      {Array(20)
        .fill(null)
        .map((_, index) => (
          <WordLine key={index} />
        ))}
    </SWrapper>
  );
});
