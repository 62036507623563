import styled from 'styled-components';
import { motion } from 'framer-motion';

export const SWrapper = styled(motion.div)`
  position: relative;
  display: flex;
  white-space: nowrap;
  padding-bottom: 100px;
`;

export const SWordsWrapper = styled(motion.div)`
  position: absolute;
  left: 0;
  display: flex;
`;
