import { create } from 'zustand';
import { readContract, waitForTransaction, writeContract } from '@wagmi/core';

import { abi } from '../../constants/abi';
import { useSupportedNetworks } from '../supportedNetworks';
import { erc20abi } from '../../constants/erc20abi';

import { IGameStore } from './types';

export const useGameStore = create<IGameStore>((set) => {
  const setIsCharLoading = (isCharLoading: boolean) => {
    set({ isCharLoading });
  };
  const setIsCurrentWordLoading = (isCurrentWordLoading: boolean) => {
    set({ isCurrentWordLoading });
  };
  const getCurrentWord = async (address: string) => {
    const { getCurrentNetworkContractAddress } =
      useSupportedNetworks.getState();

    const contractAddress = getCurrentNetworkContractAddress();

    if (!contractAddress) return;

    const [
      wordChars,
      isPlayerExist,
      attemptsLeft,
      lastOpenCharTime,
      charsLeft,
    ] = (await readContract({
      //@ts-ignore
      address: contractAddress,
      abi,
      functionName: 'getCurrentWord',
      args: [address],
    })) as any;

    set({
      currentWord: {
        wordChars,
        isPlayerExist,
        attemptsLeft: +attemptsLeft.toString(),
        lastOpenCharTime: +lastOpenCharTime.toString(),
        charsLeft: +charsLeft.toString(),
      },
    });
  };
  const openChar = async (address: string) => {
    const { getCurrentNetworkContractAddress } =
      useSupportedNetworks.getState();

    const contractAddress = getCurrentNetworkContractAddress();

    if (!contractAddress) return;

    setIsCharLoading(true);

    try {
      const tokenAddress = await readContract({
        //@ts-ignore
        address: contractAddress,
        abi,
        functionName: 'getToken',
      });

      const approveTx = await writeContract({
        //@ts-ignore
        address: tokenAddress.toString(),
        abi: erc20abi,
        functionName: 'approve',
        args: [contractAddress, 1000000],
      });

      await waitForTransaction(approveTx);

      const tx = await writeContract({
        //@ts-ignore
        address: contractAddress,
        abi,
        functionName: 'openChar',
        args: [address],
      });
      await waitForTransaction(tx);
    } catch (e) {
      console.log(e);
    }

    await getCurrentWord(address);
    setIsCharLoading(false);
  };

  const guessWord = async (address: string, word: string) => {
    const { getCurrentNetworkContractAddress } =
      useSupportedNetworks.getState();

    const contractAddress = getCurrentNetworkContractAddress();

    if (!contractAddress) return;

    try {
      const tx = await writeContract({
        //@ts-ignore
        address: contractAddress,
        abi,
        functionName: 'guessWord',
        args: [word, address],
      });
      await waitForTransaction(tx);
    } catch (e) {
      console.log(e);
    }
    await getCurrentWord(address);
  };

  const getWinners = async () => {
    const { getCurrentNetworkContractAddress } =
      useSupportedNetworks.getState();

    const contractAddress = getCurrentNetworkContractAddress();

    if (!contractAddress) return;

    set({ isWinnersLoading: true });
    await new Promise((res) => setTimeout(res, 2000));

    const winners = await readContract({
      //@ts-ignore
      address: contractAddress,
      abi,
      functionName: 'getWinners',
    });

    set({ winners: winners as string[], isWinnersLoading: false });
  };
  return {
    isCharLoading: false,
    isCurrentWordLoading: true,
    getWinners,
    isWinnersLoading: false,
    setIsCurrentWordLoading,
    setIsCharLoading,
    getCurrentWord,
    openChar,
    guessWord,
  };
});
