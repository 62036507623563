import { abi } from 'constants/abi';

import { useSettingsStore } from 'stores/settings/settings';
import { useAccount, useContractEvent } from 'wagmi';
import { useWeb3Modal } from '@web3modal/react';
import { useWallet } from 'stores/wallet';
import { useSupportedNetworks } from 'stores/supportedNetworks';
import { useEffect, useMemo, useState } from 'react';
import BigNumber from 'bignumber.js';
import { useGameStore } from 'stores/game';
import { addNotify } from 'stores/toast';

import { Network } from '../Network';

import {
  SAddress,
  SBody,
  SBodyActions,
  SButton,
  SFooter,
  SHeader,
  SHeaderBlock,
  SInput,
  SModalWrapper,
  SUnsupported,
  SWrapper,
} from './styles';
import { Word } from './Word';
import Timer from './Timer';
import { Loading } from './Loading/Loading';

export const Playground = () => {
  const [value, setValue] = useState('');
  const [isExpire, setIsExpire] = useState(true);
  const { isConnected, address } = useAccount();
  const { open } = useWeb3Modal();
  const { showRules, setIsWinners } = useSettingsStore();
  const { disconnect } = useWallet();
  const { getCurrentNetworkContractAddress } = useSupportedNetworks();
  const { openChar, guessWord, getCurrentWord, currentWord, isCharLoading } =
    useGameStore();
  const contractAddress = getCurrentNetworkContractAddress();

  const showWinners = () => {
    setIsWinners(true);
  };

  const unSub = useContractEvent({
    address: contractAddress as any,
    abi,
    eventName: 'WordGuessed',
    listener: (log: any) => {
      const [
        {
          args: { _player, _word, _prizeAmount },
          transactionHash,
        },
      ] = log;
      const lastGuessedTxHash = localStorage.getItem(
        `${contractAddress}_${transactionHash}`,
      );

      if (!lastGuessedTxHash) {
        localStorage.setItem(
          `${contractAddress}_${transactionHash}`,
          transactionHash,
        );
        return;
      }

      if (lastGuessedTxHash === transactionHash) {
        return;
      }

      addNotify(
        `The word was guessed! It was - "${_word}"
      Winner - ${_player}
      Prize amount - ${BigNumber(_prizeAmount).div(1e6).toString(10)} USDC`,
        {
          duration: 5000,
        },
      );

      localStorage.setItem(
        `${contractAddress}_${transactionHash}`,
        transactionHash,
      );

      getCurrentWord(address as string);
    },
  });

  const handleOpenChar = async () => {
    openChar(address as string);
  };

  const handleGuessWord = async () => {
    guessWord(address as string, value);
  };

  const handleChange = (e: any) => {
    setValue(e.target.value);
  };

  console.log(contractAddress, currentWord);

  useEffect(() => {
    getCurrentWord(address as string);
  }, [address, contractAddress]);

  const timer = useMemo(() => {
    if (!currentWord?.lastOpenCharTime) return 0;

    const maxSeconds = 1 * 60;

    const now = Math.floor(Date.now() / 1000);

    const secondsLeft = maxSeconds - (now - currentWord?.lastOpenCharTime);

    if (secondsLeft > 0) {
      setIsExpire(false);
    }
    return (
      <Timer onExpire={() => setIsExpire(true)} secondsLeft={secondsLeft} />
    );
  }, [currentWord?.lastOpenCharTime, isExpire]);

  useEffect(() => {
    setIsExpire(true);
  }, [currentWord?.lastOpenCharTime]);

  return (
    <SWrapper
      layout
      initial={{ opacity: 0, y: -40 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -40 }}
      transition={{ duration: 0.3 }}
    >
      <SModalWrapper>
        {isConnected ? (
          <>
            <SHeader>
              <SHeaderBlock>
                <Network />
                Network
              </SHeaderBlock>
              <SHeaderBlock>
                <SAddress>
                  {address
                    ?.slice(0, 4)
                    .concat('...')
                    .concat(
                      address?.slice(address?.length - 4, address?.length),
                    )}
                </SAddress>
                <SButton onClick={disconnect}>Disconnect</SButton>
              </SHeaderBlock>
            </SHeader>
            <SBody>
              {contractAddress ? (
                <>
                  <Word word={currentWord?.wordChars} />
                  <SBodyActions>
                    <SButton
                      disabled={!isExpire || isCharLoading || !currentWord}
                      onClick={handleOpenChar}
                    >
                      {isCharLoading ? (
                        <Loading />
                      ) : isExpire ? (
                        'Open letter'
                      ) : (
                        timer
                      )}
                    </SButton>
                    <SInput
                      placeholder="Type your guess"
                      value={value}
                      onChange={handleChange}
                    />
                    <SButton
                      disabled={!currentWord || currentWord?.attemptsLeft === 0}
                      onClick={handleGuessWord}
                    >
                      Guess word
                    </SButton>
                  </SBodyActions>
                </>
              ) : (
                <SUnsupported>Unsupported network</SUnsupported>
              )}
            </SBody>
          </>
        ) : (
          <SButton onClick={open}>Connect wallet to start!</SButton>
        )}

        <SFooter>
          <SButton onClick={showRules}>Show rules</SButton>
          {isConnected && contractAddress && (
            <SButton onClick={showWinners}>Show winners</SButton>
          )}
        </SFooter>
      </SModalWrapper>
    </SWrapper>
  );
};
