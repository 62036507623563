import styled from 'styled-components';
import { motion } from 'framer-motion';
import bgUrl from 'assets/newspaper.png';

export const SWrapper = styled(motion.div)`
  position: relative;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const SModalWrapper = styled(motion.div)`
  background: #fff;
  border: 2px outset #9b9b9b;
  //border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: url('${bgUrl}');

  padding: 20px;
  gap: 20px;
  font-family: IM Fell English SC, sans-serif;

  -webkit-box-shadow: 5px 5px 0 1px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 5px 5px 0 1px rgba(0, 0, 0, 0.2);
  box-shadow: 5px 5px 0 1px rgba(0, 0, 0, 0.2);

  @media (max-width: 950px) {
    max-width: calc(100vw - 20px);
    padding: 20px 5px;
  }
`;

export const SHeader = styled(motion.div)`
  text-align: center;
  width: 100%;
  font-family: IM Fell English SC, sans-serif;
  font-size: 18px;
`;

export const SBody = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  overflow-y: scroll;
  max-height: 400px;
  gap: 10px;

  padding-right: 5px;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #b0b0b0;
    min-height: 50px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #505256;
  }
`;

export const SWinner = styled(motion.div)``;

export const SButton = styled(motion.button)`
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  //border-radius: 8px;
  padding: 5px 20px;
  font-family: inherit;
  margin-top: 10px;
  font-size: 18px;
  color: #000;

  -webkit-box-shadow: 2px 2px 0 1px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 2px 2px 0 1px rgba(0, 0, 0, 0.2);
  box-shadow: 2px 2px 0 1px rgba(0, 0, 0, 0.2);

  min-width: 140px;
  @media (max-width: 950px) {
    font-size: 12px;
  }
`;
