const { VITE_ENV_TYPE, VITE_API_URL, VITE_WALLET_CONNECT_PROJECT_ID } =
  import.meta.env;
export const isDev = () => {
  return getEnvType() === 'dev';
};

export const isLocal = () => {
  return getEnvType() === 'local';
};

export const isStaging = () => {
  return getEnvType() === 'staging';
};

export const isProd = () => {
  return getEnvType() === 'prod';
};

export const isStagingOrProd = () => {
  return isProd() || isStaging();
};

export const isChromeBrowser = () => {
  return (
    /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor)
  );
};

export const isSafariBrowser = () => {
  let ua = navigator.userAgent.toLowerCase();
  if (ua.indexOf('safari') !== -1 && ua.indexOf('chrome') === -1) {
    return true;
  }
  return false;
};

export const getEnvType = () => {
  return VITE_ENV_TYPE;
  // return process.env.VITE_ENV_TYPE;
};

export const getApiUrl = () => {
  return VITE_API_URL;
  // return process.env.VITE_ENV_TYPE;
};

export const getWalletConnectProjectId = () => {
  return VITE_WALLET_CONNECT_PROJECT_ID;
};
