import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNetwork } from 'wagmi';
import { useSwitchNetwork } from 'wagmi';
import { AnimatePresence } from 'framer-motion';
import { useSupportedNetworks } from 'stores/supportedNetworks';
import { useClickOutside } from 'hooks/useClickOutside';

import {
  SWrapper,
  SSelectMenu,
  SSelectItem,
  SSelectedItem,
  SIconWrapper,
} from './style';

export const Network = () => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const network = useNetwork();
  const { switchNetwork } = useSwitchNetwork();
  const { supportedNetworks, currentNetwork, setCurrentNetwork } =
    useSupportedNetworks();

  const onClickOutSide = useCallback(() => {
    setIsOpen(false);
  }, []);

  useClickOutside(ref, onClickOutSide);
  const handleClick = (isAvailable: boolean, chainId: number) => () => {
    setIsOpen(false);
    if (!isAvailable) return;
    if (switchNetwork) {
      switchNetwork(chainId);
    }
  };

  useEffect(() => {
    const currentNetwork = supportedNetworks.find(
      ({ chainId }) => network.chain?.id === chainId,
    );

    if (currentNetwork) {
      setCurrentNetwork(currentNetwork);
    }
  }, [network.chain?.id, supportedNetworks]);

  const handleToggle = () => {
    setIsOpen((isOpen) => !isOpen);
  };

  return (
    <SWrapper ref={ref}>
      <SSelectedItem onClick={handleToggle}>
        {currentNetwork?.isAvailable
          ? currentNetwork.label
          : 'unsupported_network'}
      </SSelectedItem>
      <AnimatePresence>
        {isOpen && (
          <SSelectMenu
            initial={{ opacity: 0, y: -10, x: '-50%' }}
            animate={{ opacity: 1, y: 0, x: '-50%' }}
            exit={{ opacity: 0, y: -10, x: '-50%' }}
            transition={{ duration: 0.1 }}
          >
            {supportedNetworks
              .filter(({ isAvailable, isTest }) => isAvailable && !isTest)
              .map(({ label, name, chainId, isAvailable }) => (
                <SSelectItem
                  disabled={!isAvailable}
                  selected={currentNetwork?.chainId === chainId}
                  onClick={handleClick(isAvailable, chainId)}
                  key={name}
                >
                  {label}
                </SSelectItem>
              ))}
          </SSelectMenu>
        )}
      </AnimatePresence>
    </SWrapper>
  );
};
