import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Interpolation } from 'styled-components/dist/types';

export const SWrapper = styled(motion.div)<{ font: Interpolation<any> }>`
  font-size: 24px;
  padding: 40px;
  display: flex;
  ${({ font }) => font}
`;
