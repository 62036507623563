import styled from 'styled-components';
import bgUrl from 'assets/newspaper.png';

export const SWrapper = styled.div`
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  opacity: 0.7;
  filter: blur(1px);
  background: url('${bgUrl}');
`;
