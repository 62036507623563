import styled from 'styled-components';
import { motion } from 'framer-motion';
import bgUrl from 'assets/newspaper.png';

export const SWrapper = styled(motion.div)`
  font-family: IM Fell English SC, sans-serif;
  position: absolute;
  pointer-events: all;
  display: flex;
  background: url('${bgUrl}');
  border: 2px outset #9b9b9b;
  color: #000;
  align-items: center;
  margin: 5px auto 10px;
  padding-right: 5px;
  border-radius: 5px;
  line-height: 24px;
  font-size: 18px;
  font-weight: 400;
  width: calc(100% - 10px);
  max-width: 500px;

  &[data-type='danger'] {
    background: var(--c-danger);
  }

  &[data-type='warning'] {
    color: var(--c-dock);
    background: var(--c-warning);
  }

  &[data-type='success'] {
    color: var(--c-dock);
    background: var(--c-success);
  }

  &[data-size='sm'] {
    max-width: 350px;
    line-height: 18px;
    font-size: 14px;
  }
`;

export const SWrapperInner = styled.div`
  padding: 10px 15px;
  word-break: break-word;
  white-space: pre-line;
`;

export const SCloseButton = styled.button`
  display: flex;
  align-items: center;
  border: none;
  background: none;
  margin: 0 0 0 auto;
  width: 36px;
  height: 36px;
  color: var(--ui-white);
  position: relative;
  overflow: visible;

  svg.close-progress,
  [data-id='icon-close'] {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  svg.close-progress {
    flex: 0 0 26px;
    width: 26px;
    height: 26px;
    overflow: visible;
  }

  [data-id='icon-close'] {
    min-width: 24px;
    flex: 0 0 24px;
  }
`;
