import React, { FC } from 'react';
import { AnimatePresence } from 'framer-motion';
import { useNotifyStore } from 'stores/toast';

import Notify from './components/Notify';

import { SWrapper } from './style';

const NotifyProvider: FC = () => {
  const { items, remove } = useNotifyStore();

  return (
    <SWrapper>
      <AnimatePresence>
        {items.map(({ id, message, type, duration, position, size }) => (
          <Notify
            size={size}
            key={id}
            id={id}
            message={message}
            type={type}
            onClose={remove}
            duration={duration}
            position={position}
          />
        ))}
      </AnimatePresence>
    </SWrapper>
  );
};

export default NotifyProvider;
