import { create } from 'zustand';
import { getContract } from 'viem';

import { ISupportedNetworksStore } from './types';

export const useSupportedNetworks = create<ISupportedNetworksStore>(
  (set, get) => {
    return {
      networkToContractMap: {
        local: '0x8556aB17147E30ac1Bb776f841F1c0D5Ffc9dA12',
        matic: '0x8C7D0F21ABF94426b4Dc78864D7873242df9F0e5',
        arbitrum: '0x520bdd4396A6B268FA3ec0455B6D47c91d5F9d26',
      },
      supportedNetworks: [
        {
          isAvailable: true,
          isTest: false,
          chainId: 42161,
          label: 'Arbitrum One',
          name: 'arbitrum',
          rpcUrl: 'https://arb1.arbitrum.io/rpc',
        },
        {
          isAvailable: false,
          isTest: false,
          chainId: 1,
          name: 'homestead',
          label: 'Ethereum',
          rpcUrl: 'https://cloudflare-eth.com',
        },
        {
          isAvailable: true,
          isTest: false,
          chainId: 137,
          label: 'Polygon',
          name: 'matic',
          rpcUrl: 'https://polygon-rpc.com',
        },
        {
          isAvailable: false,
          isTest: false,
          chainId: 56,
          label: 'BNB Smart Chain',
          name: 'bsc',
          rpcUrl: 'https://rpc.ankr.com/bsc',
        },
        {
          isAvailable: false,
          isTest: false,
          chainId: 43114,
          label: 'Avalanche',
          name: 'avalanche',
          rpcUrl: 'https://api.avax.network/ext/bc/C/rpc',
        },
        {
          isAvailable: false,
          isTest: false,
          chainId: 10,
          label: 'Optimism',
          name: 'optimism',
          rpcUrl: 'https://mainnet.optimism.io',
        },
        {
          isAvailable: false,
          isTest: false,
          chainId: 250,
          label: 'Fantom',
          name: 'fantom',
          rpcUrl: 'https://rpc.ankr.com/fantom',
        },
        {
          isAvailable: true,
          isTest: true,
          chainId: 31337,
          label: 'Local Fork',
          name: 'local',
          rpcUrl: 'http://127.0.0.1:8545',
        },
      ],
      setCurrentNetwork(currentNetwork) {
        set({ currentNetwork });
      },
      getCurrentNetworkContractAddress() {
        const { networkToContractMap, currentNetwork } = get();

        return networkToContractMap[currentNetwork?.name!];
      },
    };
  },
);
