import { create } from 'zustand';

import { ISettingsStore } from './types';

export const useSettingsStore = create<ISettingsStore>((set) => {
  const isWelcomeDone = localStorage.getItem('isWelcome') === 'true';

  return {
    isWelcomeDone,
    isWinners: false,
    showRules() {
      set({ isWelcomeDone: false });
    },
    setIsWinners(isWinners: boolean) {
      set({ isWinners });
    },
    doneWelcome() {
      localStorage.setItem('isWelcome', 'true');
      set({ isWelcomeDone: true });
    },
  };
});
