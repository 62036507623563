import {
  EthereumClient,
  w3mConnectors,
  w3mProvider,
} from '@web3modal/ethereum';
import { configureChains, createConfig } from 'wagmi';
import {
  arbitrum,
  mainnet,
  polygon,
  bsc,
  avalanche,
  optimism,
  fantom,
} from 'wagmi/chains';
import { create } from 'zustand';
import { getWalletConnectProjectId } from 'utils/env';

import { IWalletStore } from './types';
import { local } from './constants';

const chains = [
  arbitrum,
  mainnet,
  polygon,
  bsc,
  avalanche,
  optimism,
  fantom,
  local,
];

export const useWallet = create<IWalletStore>((set, get) => {
  const projectId = getWalletConnectProjectId();

  const { publicClient } = configureChains(chains, [
    w3mProvider({ projectId }),
  ]);

  const wagmiConfig = createConfig({
    autoConnect: true,
    connectors: w3mConnectors({ projectId, chains }),
    publicClient,
  });

  const ethereumClient = new EthereumClient(wagmiConfig, chains);

  const disconnect = async () => {
    await ethereumClient.disconnect();
  };

  return {
    wagmiConfig,
    disconnect,
    ethereumClient,
    projectId,
  };
});
